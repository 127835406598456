<template>
  <div class="Login" id="box">
    <div class="login-section small">
      <div class="content">
        <router-link tag="div" to="/security" class="head">
          <h1 class="logo"><span class="green large-text-head">Linkmeup </span></h1>
          <small class="small-text-head"><b>Security</b></small>
        </router-link>
        <!-- <p class="small-text">Identity & Access Management</p> -->

        <div style="color: black">
          <form autocomplete="OFF">
            <h2>Hello!</h2>
            <div class="success-msg" v-if="$store.state.auth.loginSuccess">
              {{ $store.state.auth.loginSuccess }} 
            </div>
            <div class="error-msg" v-if="$store.state.auth.loginError">
              {{ $store.state.auth.loginError }}
            </div>
            <div>
              <div class="fake-input">
                <input type="text" placeholder="Email/Username" v-model="username"> <i class="fa fa-user" ></i>
              </div>
            </div>
            <div>
              <div class="fake-input">
                <input type="password" placeholder="Password" v-model="password"> <i class="fa fa-lock"></i>
              </div>
            </div>
            <div class="forgot-link">
              <router-link to="/security/forgot-password">Forgot password?</router-link>
            </div>
            <div style="margin-bottom: 1.5em">
              <Button :style="{ color: '#fff', backgroundColor: '#25a244de', width: '100%' }" text="Login" @click.prevent="login"/>
            </div>
            <div class="forgot-link">
              Don't have an account? <router-link to="/security/signup">Sign up</router-link>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Button from '@/components/Button.vue'
import platform from 'platform'
// @ is an alias to /src

export default {
  name: "Login",
  components: {
    Button
  },
  data(){
    return {
      username: "",
      password: ""
    }
  },
  methods: {
    login() {
      const navigator_info = window.navigator
      const screen_info = window.screen
      let uid = navigator_info.mimeTypes.length
      uid += navigator_info.userAgent.replace(/\D+/g, '');
      uid += navigator_info.plugins.length;
      uid += screen_info.height || '';
      uid += screen_info.width || '';
      uid += screen_info.pixelDepth || '';
      const deviceId = uid
      const deviceType = platform.os.family

      this.$store.state.loading = true
      this.$store.dispatch('login', { userName: this.username, password: this.password, deviceId, deviceType })
    }
  }
};
</script>

<style scoped>
  .Login {
    padding: 30px 20px;
    height: 100%;
  }

  .content h1 {
    font-size: 35px;
    /* margin-bottom: 1rem; */
    text-align: center;
  }

  .green {
    color: #25a244de;
  }

  label {
    color: #000;
  }

  .content p {
    text-align: center;
    font-size: 15px;
  }

  .head {
    color: #25a244de;
    text-align: center;
    cursor: pointer;
    margin-bottom: 1rem;
    display: block;
    text-decoration: none;
  }

  .content > p, .content > form {
    margin-bottom: 3rem;
  }

  .content p {
    font-weight: bold;
  }

  form > div {
    margin-bottom: 10px;
  }

  .btn .button:hover {
    background-color: #29793d6e;
    padding: 10px 0;
    border-radius: 5px;
    transition: background 0.3s ease-out;
  }

  .forgot-link {
    padding-bottom: 1em;
    text-align: right;
    font-size: 13px;
  }

  .forgot-link a:hover {
    font-weight: bold;
  }

  .forgot-link a {
    color: #25a244de;
    text-decoration: none;
  }

  form {
    padding: 0 1rem;
  }

  form input {
    border: none;
  }

  form .fake-input {
    border-bottom: 1px solid #ccc;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  form .fa-user, form .fa-lock {
    color: #0a090887;
  }

  .large-text-head {
        font-size: 45px;
    }

  form h2 {
    padding-bottom: 15px;
  }

  .error-msg {
    color: red;
    font-size: 14px;
  }

  .success-msg {
    color: #fff;
    background: green;
    font-size: 14px;
    padding: 10px;
    font-weight: 600;
    border-radius: 3px;
  }
</style>
